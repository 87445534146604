import * as session from "browser-store";
import Cookies from "universal-cookie";

function makeid(length) {
  var result = "";
  var characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

class ClientSession {
  static authkey = "auth";
  static loggedin = null;
  static cookies = new Cookies();

  static getDeviceID = (func) => {
    session.get("__main_reprice", (err, value) => {
      if (!value) {
        let new_id = makeid(45);
        session.put("__main_reprice", new_id, (err) => func(new_id));
      } else {
        func(value);
      }
    });
  };

  static storeAuth = (value, func) => {
    session.put(ClientSession.authkey, value, (err) => func(err));
  };

  static getAuth = (reciverfunc) => {
    session.get(ClientSession.authkey, (err, value) => reciverfunc(err, value));
  };

  static removeAuth = (func) => {
    session.remove(ClientSession.authkey, (err) => {
      func(err);
    });
  };

  static isLoggedIn = (func) => {
    ClientSession.getAuth((err, value) => {
      if (err) {
        console.error(err);
        func(false);
      } else {
        if (value == null) {
          func(false);
        } else if (value.token) {
          func(true);
        } else {
          ClientSession.removeAuth((err) => {});
          func(false);
        }
      }
    });
  };

  static getToken = () => {
    if (ClientSession.isLoggedIn()) {
      ClientSession.getAuth((err, value) => {
        if (err) {
          console.error(err);
          return false;
        } else {
          return value.token;
        }
      });
    }
  };

  static getAccessToken = (callback) => {
    ClientSession.isLoggedIn(function (isLoggedIn) {
      if (isLoggedIn) {
        ClientSession.getAuth((err, value) => {
          if (err) {
            console.error(err);
            callback(false, err);
          } else {
            callback(true, value);
          }
        });
      } else {
        callback(false, null);
      }
    });
  };
}

export default ClientSession;
